import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reimbursment-documents"
export default class extends Controller {
  connect() {

    $('.invoice_datepicker').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true, 
      endDate: new Date()
    })

    $('.add-new-item').click(function(){
      $('.reimbursement-nested-item-heading').removeClass('d-none')
    })
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('name')){
            $(input).rules( "add", {
              required: true,
              minlength: 2,
              maxlength: 50,
              nameregex: true,
              messages: {
                required: "Please enter document name.",
                minlength: 'Please enter atleast 2 characters.',
                maxlength: 'Please do not enter more than 50 characters.',
                nameregex: 'Only alphabets are allowed.'
              }
            });
          }
        }
      })
    })
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('invoice_datepicker')){
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Please select invoice date.",
              }
            });
          }
        }
      })
    })
    
     $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'file') {
          if($(input).attr('class').includes('document-file')){
            $(input).rules( "add", {
              required: true,
              messages: {
                required: "Please enter vdocument-file.",
              }
            });
          }
        }
      })
    })

    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('vendors_name')){
            $(input).rules( "add", {
              required: true,
              minlength: 3,
              maxlength: 100,
              nameregex: true,
              messages: {
                required: "Please enter vendor name.",
                minlength: 'Please enter atleast 3 characters.',
                maxlength: 'Please do not enter more than 100 characters.',
                nameregex: 'Only alphabets are allowed.'
              }
            });
          }
        }
      })
    })
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('bill_number')){
            $(input).rules( "add", {
              required: true,
              minlength: 3,
              maxlength: 20,
              alphanumericOnly: true,
              messages: {
                required: "Please enter bill number.",
                minlength: 'Bill number must have minimum 3 and maximum 20 characters.',
                maxlength: 'Bill number must have minimum 3 and maximum 20 characters.',
                alphanumericOnly: 'Special characters are not allowed.'
              }
            });
          }
        }
      })
    })
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('gst_number')){
            $(input).rules( "add", {
              required: true,
              minlength: 15,
              maxlength: 15,
              alphanumericOnly: true,
              messages: {
                required: "Please enter GST number.",
                minlength: 'Gst number must be 15 characters long.',
                maxlength: 'Gst number must be 15 characters long.',
                alphanumericOnly: 'Special characters are not allowed.'
              }
            });
          }
        }
      })
    })
    $('#reimbursment_documents').find(".nested-fields").each(function(index, element) {
      $(element).find('input').each(function(i, input) {
        if ($(input).attr('type') == 'text') {
          if($(input).attr('class').includes('amount')){
            $(input).rules( "add", {
              required: true,
              min: 1,
              maxlength: 15,
              number_regex_decimal: true,
              messages: {
                required: "Amount is required. Please enter a value.",
                min: 'Amount must be greater then zero.',
                maxlength: 'Please enter valid amount.',
                number_regex_decimal: 'Only enter number'
              }
            });
          }
        }
      })
    })
    $.validator.addMethod("alphanumericOnly",function (value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]+$/.test(value);
    },"Special characters are not allowed.");
    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d.]+(\.\d+)?$/.test(value);
    }, 'Only digits, and decimals are allowed');
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });
  }
}
