import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='meeting'
export default class extends Controller {
  connect() {
    $.validator.addMethod("emailregex", function(value, element) {
      let y = true
      for(let x of value) {
        y = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b$/i.test(x);
        if (y === false) {
          y = false
        }
      }
      return this.optional(element) || y
    });

    $(".MeetingSettingFrom").validate({
      rules: {
        'meeting_setting[reminder_time]': {
          required: true,
          number: true,
          min: 0,
          maxlength: 3

        },
      },
      messages: {
        'meeting_setting[reminder_time]': {
          required: "Please enter a value in minutes.",
          number: "Please enter a valid number.",
        }
      },
    });

    $('.meeting_datepicker').datepicker({
      format: 'dd/mm/yyyy',
      startDate: '0d',
    })
    $('.RescheduleFrom').validate({
      rules: {
        'meeting[start_time]': {
          required: true
        },
        'meeting[end_time]': {
          required: true
        }
      },
      messages: {
        'meeting[start_time]': {
          required: 'Please select start time.'
        },
        'meeting[end_time]': {
          required: 'Please select end time.'
        }
      }
    });
    // $('.EditMeetingFrom').validate({
    //   rules: {
    //     'meeting[title]': {
    //       required: true,
    //       minlength: 3,
    //       maxlength: 50
    //     },
    //     "meeting[other_members][]": {
    //       emailregex: true
    //     },
    //   },
    //   messages: {
    //     'meeting[title]': {
    //       required: 'Please enter title.',
    //     },
    //     'meeting[other_members][]':{
    //       emailregex: 'please enter valid email'
    //     }
    //   }
    // });
    console.log('meeting-tab1')
    var hasOtherMembersField = $('.meeting-from').find('[name="meeting[other_members][]"]').length > 0;
    $('.meeting-from').validate({
      rules: {
        'meeting[title]': {
          required: true,
          minlength: 3,
          maxlength: 50
        },
        'meeting[start_time]': {
          required: true
        },
        'meeting[end_time]': {
          required: true
        },
        "meeting[other_members][]": {
          emailregex: true,
          required: function(element) {
            return $('#employee_ids').val().length == 0
          }
        },
        'employee_ids[]': {
          required: function(element) {
            // Only require employee_ids if other_members field doesn't exist or is empty
            return hasOtherMembersField ? $('#other-members-select').val().length == 0 : true; 
          }
        },
        'meeting[description]':{
          required: true,
          minlength: 10
        }
      },
      messages: {
        'meeting[title]': {
          required: 'Please enter title.',
        },
        'meeting[start_time]': {
          required: 'Please select start time and date.'
        },
        'meeting[end_time]': {
          required: 'Please select end time.'
        },
        'meeting[other_members][]': {
          emailregex: 'please enter valid email.',
          required: 'Please select guest.'
        },
        'employee_ids[]': {
          required: 'please select members.'
        },
        'meeting[description]':{
          required: 'Description is required.',
          minlength: 'Please enter atleast 10 characters.'
        }
      }
    });

    $('.SeparateMeeting').validate({
      rules: {
        'meeting[title]': {
          required: true,
          minlength: 3,
          maxlength: 50
        },
        'meeting[start_time]': {
          required: true
        },
        'meeting[end_time]': {
          required: true
        }
      },
      messages: {
        'meeting[title]': {
          required: 'Please enter title.',
        },
        'meeting[start_time]': {
          required: 'Please select start time and date.'
        },
        'meeting[end_time]': {
          required: 'Please select end time.'
        }
      }
    });
    customSelect2('.employee_ids', true, 'Select team member email')

    $(".other-members-select").select2({
      tags: true,
      tokenSeparators: [',', ' '],
      placeholder: 'Select other employee email'
    })
  }
}


