import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoice-line-item"
export default class extends Controller {
  connect() {
    let taskListOption = document.getElementById('task_list_option')
    let billingLogs = document.getElementById('billing_logs')


    if (taskListOption.value.length > 0) {
      taskListOption = JSON.parse(taskListOption.value)
      const openTasks = taskListOption.filter(task => task[3] !== 'closed');

      $('.line-item-task-select').each(function(index, item) {
        var taskSelect = $(item);
        var selectvalue = taskSelect.val()
        taskSelect.find('option').remove()
        $.each(openTasks, function(index, value) {
          $("#billing").val(value[0])
          var option = $('<option>').val(value[2]).text(value[0]);
          option.attr('data-hour', value[1])
          option.attr('data-status', value[3])
          taskSelect.append(option);
        });
        taskSelect.val(selectvalue)
      });
    }


    if(billingLogs.value.length > 0){
      console.log(billingLogs.value)
      billingLogs = JSON.parse(billingLogs.value)
      $('.currency-symbol').text(`(${billingLogs.currency})`)
      $('.line-item-unit-select').each(function(index, item) {
        var taskSelect = $(item);
        // var selectvalue = taskSelect.val()
        if(taskSelect.find('option').length <= 1){
          taskSelect.find('option').remove()
          var options = [
            ['', 'Select'],
            ['day', 'Day'],
            ['hour', 'Hour'],
            ['week', 'Week'],
            ['month', 'Month'],
            ['fixed_rate', 'Fixed Rate']
          ]
          $.each(options, function(index, value) {
            var option = $('<option>').val(value[0]).text(value[1]);
            if(billingLogs.unit == value[0]){
              option.attr('data-unit', billingLogs.per_unit_price)
            }else{
              option.attr('data-unit', 0)
            }
            option.attr('data-currency', billingLogs.currency)
            taskSelect.append(option);
          });
          taskSelect.val(billingLogs.unit)
          let row = $(item).parent().parent().parent()
          let unit = $(item).find(`option[value='${item.value}']`).data('unit')
          row.find('input.line-item-per-unit-amount').val(unit)
          row.find('input.line-item-unit-quantity').change();
          $('input.line-item-amount').change();
        }
      });
    }

    $('.line-item-unit-select').change(function() {
      let row = $(this).parent().parent().parent()
      let unit = $(this).find(`option[value='${this.value}']`).data('unit')
      row.find('input.line-item-per-unit-amount').val(unit)
      row.find('input.line-item-unit-quantity').change();
      $('input.line-item-amount').change();
    })

    $('.line-item-task-select').change(function() {
      let row = $(this).parent().parent().parent()
      let qty = $(this).find(`option[value='${this.value}']`).data('hour')
      row.find('input.line-item-unit-quantity').val(qty)
      row.find('input.line-item-unit-quantity').change();
      $('input.line-item-amount').change();
    })

    $('input.line-item-unit-quantity').change(function() {
      let row = $(this).parent().parent().parent()
      console.log(row)
      let unitPrice = row.find('input.line-item-per-unit-amount').val();
      console.log(unitPrice)
      let qty = this.value
      console.log(qty)
      let amount = row.find('input.line-item-amount');
      console.log(amount)

      if(unitPrice.length > 0 && qty.length > 0){
        amount.val(parseFloat(unitPrice) * parseFloat(qty))
      }
      $('input.line-item-amount').change();
    })

    $('input.line-item-per-unit-amount').change(function() {
      let row = $(this).parent().parent().parent()
      let unitPrice = this.value
      let qty = row.find('input.line-item-unit-quantity').val();
      let amount = row.find('input.line-item-amount');
      console.log(unitPrice)
      console.log(qty)
      if(unitPrice.length > 0 && qty.length > 0){
        amount.val(parseFloat(unitPrice) * parseFloat(qty))
      }
      $('input.line-item-amount').change();
    })
    $('input.line-item-amount').change();
    $('#invoice_request_advance_amount, #invoice_request_adjustment_amount').change(function() {
      $('input.line-item-amount').change();
    });
    $(document).on('input', '.line-item-per-unit-amount, .line-item-unit-quantity', function() {
      var val = $(this).val();
      // Remove any characters after the second decimal point
      if(val.indexOf('.') !== -1) {
        var splitVal = val.split('.');
        if(splitVal[1].length > 2) {
          splitVal[1] = splitVal[1].substring(0, 2);
          val = splitVal.join('.');
          $(this).val(val);
        }
      }
    });


    $(document).on('cocoon:before-remove', function(event, $link) {
      event.preventDefault(); // Stop immediate removal until confirmed

      var iconType = $link.data('icon') || 'warning2';
      var confirmMessage = $link.data('confirm-message') || 'Are you sure?<br> You want to delete this line item?';
      var confirmText = $link.data('confirm-text') || 'OK';
      var cancelText = $link.data('cancel-text') || 'Cancel';
      var htmlContent = iconType == 'warning2' ? '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>' : '';
      htmlContent += `<div class="swal2-html-container">${confirmMessage}</div>`;

      // SweetAlert confirmation dialog
      Swal.fire({
        icon: iconType,
        html: htmlContent,
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText
      }).then(function(result) {
        if (result.isConfirmed) {
          // Locate the _destroy input field within the same container and set it to "true"
          $link.closest('.nested-fields').find("input[type='hidden'][name$='[_destroy]']").val('true');
          $link.closest('.nested-fields').hide(); // Optionally hide the field
          $link.trigger('change'); // Notify form about changes
        }
      });
    });


    $(document).on('cocoon:after-remove', function(e, removedItem) {
      console.log("A row has been removed!");

      let total = 0
      let tax_rate = 0
      tax_rate = $('#tax-select-box').find('option:selected').data('rate')

      $('.line-item-amount').each(function(index, element) {
        if($(element).parent().parent().parent().is(":visible")) {
          if($(element).val().length > 0){
            total = total + parseFloat($(element).val())
          }
        }
      })

      if (tax_rate > 0){
        let tax_amount = 0
        let amount = 0

        tax_amount = ((total * tax_rate)/100)

        total = (total - tax_amount)
      }

      $('#invoice_request_line_items_amount').val(total)
      var advanceAmount = $('#invoice_request_advance_amount').val() || 0
      $('#invoice_request_total_invoice_amount').val(total)
      var adjustmentAmount = $('#invoice_request_adjustment_amount').val() || 0
      $('.payable_amount').val(total - parseFloat(advanceAmount) - parseFloat(adjustmentAmount))
    });

    $('input.line-item-amount').change(function() {
      let total = 0
      let tax_rate = 0
      tax_rate = $('#tax-select-box').find('option:selected').data('rate')

      $('.line-item-amount').each(function(index, element) {
        if($(element).val().length > 0){
          total = total + parseFloat($(element).val())
        }
      })
      $('#invoice_request_line_items_amount').val(total)

      let tax_amount = 0
      if (tax_rate > 0){
        let amount = 0
        tax_amount = ((total * tax_rate)/100)
        total = (total + tax_amount)
      }
      $('#invoice_request_tax_amount').val(tax_amount)

      var advanceAmount = $('#invoice_request_advance_amount').val() || 0
      $('#invoice_request_total_invoice_amount').val(total)
      var adjustmentAmount = $('#invoice_request_adjustment_amount').val() || 0
      $('.payable_amount').val(total - parseFloat(advanceAmount) - parseFloat(adjustmentAmount))
    });

  }

  // updateQty(event){
  //   let taskListOption = document.getElementById('task_list_option')
  //     console.log(element)
  //   if(taskListOption.value.length > 0){
  //     taskListOption = JSON.parse(taskListOption.value)
  //     let element = $(event.target)
  //     console.log(element)
  //     let parent = element.parent().parent().parent('.nested-fields')
  //     $(taskListOption).filter(function(index, value) {
  //         if(value[2] == element.val()){
  //           parent.find('.line-item-unit-quantity').val(value[1])
  //         }
  //     })
  //   }
  // }

  // updateUnitPrice(event) {
  //   let billingLogs = document.getElementById('billing_logs').value
  //   billingLogs = JSON.parse(billingLogs.value)
  //   let element = $(event.target)
  //   let parent = element.parent().parent().parent('.nested-fields')
  //   let unit = parent.find('.line-item-unit-select').val()
  //   console.log(element)
  //   let selected_label = (element.val());
  //   if(selected_label == 'fixed_rate'){
  //     parent.find('.line-item-per-unit-amount').attr('disabled','disabled');
  //     parent.find('.line-item-unit-quantity').attr('disabled','disabled');
  //     parent.find('.line-item-per-unit-amount').val('');
  //     parent.find('.line-item-unit-quantity').val('');
  //     parent.find('.line-item-amount').val(billingLogs[unit]);
  //   }else{
  //     let billingLogPerUnit = parent.find('.line-item-per-unit-amount').val();
  //     if(event.target.classList.contains('line-item-unit-select')){
  //       billingLogPerUnit = billingLogs[unit]
  //     }
  //     let perUnit = parent.find('.line-item-per-unit-amount').val(billingLogPerUnit)
  //     parent.find('.line-item-per-unit-amount').removeAttr('disabled');
  //   }
  //   calculation(parent)
  // }

  // totalLineitemAmount(event){
  //   totalAmount()
  // }
}
