import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="candidate"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".employee-candidate").toggleClass("order-enable");
      $(".organizers-candidate").toggleClass("order-enable");
    });
    customSelect2('#candidate_country_code', true);
    $(document).ready(function () {
      $('.interview_select').select2({
        placeholder: "Select interviewer",
        allowClear: true,
      });

      $(document).on('contentUpdated turbo:load', function () {
        $('.interview_select').select2({
          placeholder: "Select interviewer",
          allowClear: true,
        });
      });
    });


    $("input[name='option']").change(function(e){ toggle() });
    toggle()
    function toggle(){
      console.log('in toggle')
      var radioElement = document.querySelector('input[name="option"]:checked');
      if (radioElement) {
        var selectedOption = radioElement.value;
        var formSections = document.querySelectorAll(".form-section");

        formSections.forEach(section => {
          console.log('id : ' + section.id)
          console.log(`value = formSection-${selectedOption}`)
          if (section.id === `formSection-${selectedOption}`) {
            section.style.display = "block";
          } else {
            section.style.display = "none";
          }
        });
      }
    }


    var radio_value = $(".form-check-input:checked").val()
    if (radio_value){
      console.log(radio_value!='')
      $("#feedback").attr('required', true);
    }
    $('.form-check-input').on('change', function(){
      radio_value = $(".form-check-input:checked").val()
      if (radio_value){
        console.log(radio_value!='')
        $("#feedback").attr('required', true);
        $("#feedback").attr('minlength', 20);
      }
    });


    $('#candidate_source').on('change', function(){
      var source = $(this).val()
      if (source == 'reference') {
        $('#other_details').addClass('d-none')
        $('#other_details').removeClass('d-block')
        $('.reference_details').addClass('d-block')
        $('.reference_details').removeClass('d-none')
      } else if ( source == 'other') {
        $('#other_details').addClass('d-block')
        $('#other_details').removeClass('d-none')
        $('.reference_details').addClass('d-none')
        $('.reference_details').removeClass('d-block')
      } else {
        $('#other_details').addClass('d-none')
        $('#other_details').removeClass('d-show')
        $('.reference_details').addClass('d-none')
        $('.reference_details').removeClass('d-block')
      }
    });
    $('.candidate_dob').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })
    $(document).on('cocoon:after-insert', function(e, insertedItem) {
      var parentInterviewRound = $(insertedItem).closest('.feedback-description-fields');
      console.log(parentInterviewRound)

      var questionForms = parentInterviewRound.find('.question-and-answer-type');
      console.log(questionForms)

      questionForms.each(function(index) {
        $(this).find('.question-number').text('Question ' + (index + 1));
      });
    });

    $('#accordionResponse').find('.feedback-description-fields').each(function(index, questionForms){
      $(questionForms).find('.nested-fields').each(function(index) {
        $(this).find('.question-number').text('Question ' + (index + 1));
      })
    });

    $.validator.addMethod("dateRequired", $.validator.methods.required,
      "Please select date");
    $.validator.addMethod("timeRequired", $.validator.methods.required,
      "Please select time");
    $.validator.addMethod("interviewerRequired", $.validator.methods.required,
      "Please select interviewer");
    $.validator.addMethod("feedbackRequired", $.validator.methods.required,
      "Please enter feedback");
    $.validator.addClassRules("selection_round_response_date", {
      dateRequired: true,
    });
    $.validator.addClassRules("selection_round_response_time", {
      timeRequired: true,
    });
    $.validator.addClassRules("selection_round_response_interviewer", {
      interviewerRequired: true,
      minlength: 3,
    });

    $('.edit_selection_round_response').validate({
      rules: {
        'selection_round_response[interview_date]':{
          required: true,
        },
        'selection_round_response[interview_time]':{
          required: true,
        },
      },
      messages: {
        'selection_round_response[interview_date]':{
          required:'Please select date.',
        },
        'selection_round_response[interview_time]':{
          required:'Please select time.',
        },
      },
      errorElement: "span"
    })

    $('.new_candidate').validate({
      rules: {
        'candidate[name]':{
          required:true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        },
        'candidate[email_id]':{
          required:true,
          emailRegex: true,
        },
        'candidate[contact_number]':{
          required:true,
          digits: true,
          min: 1000000000,
          minlength: 10,
          maxlength: 10,
        },
        'candidate[alternate_number]':{
          digits: true,
          min: 1000000000,
          minlength: 10,
          maxlength: 10,
        },
        'candidate[gender]':{
          required:true,
        },
        'candidate[status]':{
          required:true,
        },
        'candidate[job_opening_id]':{
          required:true,
        },
        'candidate[source]':{
          required:true,
        },
        'candidate[resume]':{
          required:true,
        },
        'candidate[address]':{
          minlength: 3,
        },
        'candidate[city]':{
          lettersonly: true,
          minlength: 3,
          maxlength: 50,
        },
        'candidate[state]':{
          lettersonly: true,
          minlength: 2,
          maxlength: 50,
        },
        'candidate[zip_code]':{
          minlength: 4,
          maxlength: 6,
          step: 1,
          min:1000,
        },
        'candidate[reference_details]':{
          required: true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        },
        'candidate[reference_name]':{
          required: true,
        },
        'candidate[other_details]':{
          required:true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        }
      },
       messages: {
        'candidate[name]':{
          required:'Please enter candidate name.',
          minlength: 'Enter minimum 3 and maximum 50 characters.',
          maxlength: 'Enter minimum 3 and maximum 50 characters.',
        },
        'candidate[email_id]':{
          required: 'Please enter Email id.',
          emailRegex: 'Please enter valid email id. Allow only . and @ as a special character.',
        },
        'candidate[contact_number]':{
          required: 'Please enter contact number.',
          digits: 'Only numbers are allowed.',
          min: 'Please enter valid contact number.',
          minlength: 'Minimum 10 digits are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
        },
        'candidate[alternate_number]':{
          digits: 'Only numbers are allowed.',
          min: 'Please enter valid alternate number.',
          minlength: 'Minimum 10 digits are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
        },
        'candidate[gender]':{
          required:'Please select a gender.',
        },
        'candidate[status]':{
          required:'Please select hiring status.',
        },
        'candidate[job_opening_id]':{
          required: 'Please select a job title.',
        },
        'candidate[source]':{
          required: 'Please select a source.',
        },
        'candidate[zip_code]':{
          step: 'Please enter valid zip code.',
          min: 'Please enter valid zip code.',
        },
        'candidate[resume]':{
          required: 'Please upload the Resume.',
        },
        'candidate[reference_details]':{
          required: 'Please enter reference details.',
        },
        'candidate[reference_name]':{
          required: 'Please select employee name.',
        },
        'candidate[other_details]':{
          required: 'Please enter other details.',
        }
      },
      errorElement: "span"
    });

    $('.edit_candidate').validate({
      rules: {
        'candidate[name]':{
          required:true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        },
        'candidate[email_id]':{
          required:true,
          emailRegex: true,
        },
        'candidate[contact_number]':{
          required:true,
          minlength: 10,
          maxlength: 10,
        },
        'candidate[alternate_number]':{
          minlength: 10,
          maxlength: 10,
        },
        'candidate[gender]':{
          required:true,
        },
        'candidate[status]':{
          required:true,
        },
        'candidate[job_opening_id]':{
          required:true,
        },
        'candidate[source]':{
          required:true,
        },
        'candidate[address]':{
          minlength: 3,
        },
        'candidate[city]':{
          lettersonly: true,
          minlength: 3,
          maxlength: 50,
        },
        'candidate[state]':{
          lettersonly: true,
          minlength: 2,
          maxlength: 50,
        },
        'candidate[zip_code]':{
          minlength: 4,
          maxlength: 6,
          step: 1,
          min:1000,
        },
        'candidate[reference_details]':{
          required:true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        },
        'candidate[reference_name]':{
          required: true,
        },
        'candidate[other_details]':{
          required:true,
          minlength: 3,
          maxlength: 50,
          lettersonly: true,
        }
      },
       messages: {
        'candidate[name]':{
          required:'Please enter candidate name.',
          minlength: 'Enter minimum 3 and maximum 50 characters.',
          maxlength: 'Enter minimum 3 and maximum 50 characters.',
        },
        'candidate[email_id]':{
          required: 'Please enter Email id.',
          emailRegex: 'Please enter valid email id.'
        },
        'candidate[contact_number]':{
          required: 'Please enter contact number.',
          minlength: 'Minimum 10 digits are allowed.',
          maxlength: 'maximum 10 digits are allowed.',
        },
        'candidate[alternate_number]':{
          minlength: 'Minimum 10 digits are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
        },
        'candidate[gender]':{
          required:'Please select a gender.',
        },
        'candidate[status]':{
          required:'Please select hiring status.',
        },
        'candidate[job_opening_id]':{
          required: 'Please select a job.',
        },
        'candidate[source]':{
          required: 'Please select a source.',
        },
        'candidate[zip_code]':{
          step: 'Please enter valid zip code.',
          min: 'Please enter valid zip code.',
        },
        'candidate[reference_details]':{
          required: 'Please enter reference details.',
        },
        'candidate[reference_name]':{
          required: 'Please select employee name.',
        },
        'candidate[other_details]':{
          required: 'Please enter other details.',
        }
      },
      errorElement: "span"
    });
    jQuery.validator.addMethod("lettersonly", function(value, element) {
    return this.optional(element) || /^[a-z. ]+$/i.test(value);
    }, "Only alphabates are allowed");
    jQuery.validator.addMethod("emailRegex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9]+(?:[._%+()-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/i.test(value);
    }, "Enter valid email address");
  }
}



