import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoice"
export default class extends Controller {
  connect() {

    $('#add-project-btn').click(function(e) {
      e.preventDefault();
      $(this).hide(); // Hide the button
      $('#select-container').removeClass('hidden').find('select').focus(); // Show the select box and focus on it
    });

    $('#custom-add-project-btn').click(function(e) {
      e.preventDefault();
      var $projectDetails = $('#project-details');
      var $projectBtn = $('#custom-add-project-btn');
      $projectDetails.find('input').val('');
      $projectDetails.find('select').prop('selectedIndex', 0);

      if ($projectDetails.hasClass('hidden')) {
        console.log('show project');
        $projectBtn.empty().append('<p>Enter Project details</p>');
        $projectDetails.removeClass('hidden');
      } else {
        console.log('hide project');
        $projectBtn.empty().append('<p>Add Project</p>');
        $projectDetails.addClass('hidden');
      }
    });

    $('#custom-add-customer-btn').click(function(e) {
      e.preventDefault();
      var $customerDetails = $('#customer-details');
      var $customerBtn = $('#custom-add-customer-btn');
      $customerDetails.find('input').val('');
      $customerDetails.find('select').prop('selectedIndex', 0);

      if ($customerDetails.hasClass('hidden')) {
        console.log('show customer');
        $customerBtn.empty().append('<p>Enter Customer details</p>');
        $customerDetails.removeClass('hidden');
      } else {
        console.log('hide customer');
        $customerBtn.empty().append('<p>Add Customer</p>');
        $customerDetails.addClass('hidden');
      }
    });

    if($('#invoice_request_custom_invoice_true').is(":checked")){
      $('.custom_invoice_project_row').removeClass('hidden')
      $('.custom_task_line_rows').removeClass('hidden')
      $('.project_wise_invoice_row').hide()
      $('.line-items-box').removeClass('invoice_line_items_section')
      $('.line_item_btn').removeClass('d-flex')
      $('.line_item_btn').hide()
      $('.nested-fields').find('.invoice_line_item_name').removeClass('d-none');
      $('.nested-fields').find('.line-item-task-select').parent().addClass('d-none')
    }else{
      $('.custom_invoice_project_row').addClass('hidden')
      $('.custom_task_line_rows').addClass('hidden')
      $('.project_wise_invoice_row').show()
      $('.line-items-box').addClass('invoice_line_items_section') 
      $('.line_item_btn').addClass('d-flex')
      $('.line_item_btn').show()
      $('.nested-fields').find('.invoice_line_item_name').addClass('d-none');
      $('.nested-fields').find('.line-item-task-select').parent().removeClass('d-none')
    }

    $('.invoice_type input[type=radio]').change(function () {
      if($(this).val() == 'true'){
        $('.custom_invoice_project_row').removeClass('hidden')
        $('.custom_task_line_rows').removeClass('hidden')
        $('.project_wise_invoice_row').hide()
        $('.line-items-box').removeClass('invoice_line_items_section')
        $('.line_item_btn').removeClass('d-flex')
        $('.line_item_btn').hide()
      }else{
        $('.custom_invoice_project_row').addClass('hidden')
        $('.custom_task_line_rows').addClass('hidden')
        $('.project_wise_invoice_row').show()
        $('.line-items-box').addClass('invoice_line_items_section')
        $('.line_item_btn').addClass('d-flex')
        $('.line_item_btn').show()
      }
    })

    customSelect2('#project-invoice', false);
    function isLeftSectionFilled() {
      return $(".left-section").toArray().some(field => $(field).val());
    }
    function isRightSectionFilled() {
      return $(".right-section").toArray().some(field => $(field).val());
    }
    $(".invoice_validation").validate({
      rules: {
        "invoice_request[details][project][name]": {
          required: function () {
            return isLeftSectionFilled() || !isRightSectionFilled();
          },
          alphanumeric: true,
          minlength: 3,
          maxlength: 25
        },
        "invoice_request[details][project][start_date]": {
          required: function () {
            return isLeftSectionFilled() || !isRightSectionFilled();
          }
        },
        "invoice_request[details][project][end_date]": {
          required: function () {
            return isLeftSectionFilled() || !isRightSectionFilled();
          }
        },
        "invoice_request[details][project][status]": {
          required: function () {
            return isLeftSectionFilled() || !isRightSectionFilled();
          }
        },
        "invoice_request[details][project][type]": {
          required: function () {
            return isLeftSectionFilled() || !isRightSectionFilled();
          }
        },
        "invoice_request[details][customer][name]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          nameregex: true,
          minlength: 3,
          maxlength: 25
        },
        "invoice_request[details][customer][email]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          emailRegex: true,
        },
        "invoice_request[details][customer][contact_number]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          digits: true,
          minlength: 10,
          maxlength: 10
        },
        "invoice_request[details][customer][address]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          minlength: 5,
          maxlength: 100
        },
        "invoice_request[details][customer][city]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          minlength: 3,
          maxlength:25
        },
        "invoice_request[details][customer][state]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          },
          minlength: 2,
          maxlength: 25
        },
        "invoice_request[details][customer][country]": {
          required: function () {
            return isRightSectionFilled() || !isLeftSectionFilled();
          }
        },
      },
      messages: {
        "invoice_request[details][project][name]": {
          required: "Project name is required.",
          alphanumeric:'Please enter valid project name.',
          minlength: "Project name must contain at least 3 characters.",
          maxlength: "Project name must contain at most 25 characters."
        },
        "invoice_request[details][project][start_date]": {
          required: "Start date is required."
        },
        "invoice_request[details][project][end_date]": {
          required: "End date is required."
        },
        "invoice_request[details][project][status]": {
          required: "Project status is required."
        },
        "invoice_request[details][project][type]": {
          required: "Project type is required."
        },
        "invoice_request[details][customer][name]": {
          nameregex:'Please enter valid customer name.',
          required: "Customer name is required.",
          minlength: "Customer name must contain at least 3 characters.",
          maxlength: "Customer name must contain at most 25 characters."
        },
        "invoice_request[details][customer][email]": {
          required: "Customer email is required.  Test",
          emailRegex: "Please enter a valid email address."
        },
        "invoice_request[details][customer][contact_number]": {
          required: "Contact number is required.",
          digits: "Contact number must contain only digits.",
          minlength: "Contact number must contain exactly 10 digits.",
          maxlength: "Contact number must contain exactly 10 digits."
        },
        "invoice_request[details][customer][address]": {
          required: "Customer address is required.",
          minlength: "Customer address must contain at least 5 characters.",
          maxlength: "Customer address must contain at most 100 characters."
        },
        "invoice_request[details][customer][city]": {
          required: "City is required.",
          minlength: "City must contain at least 3 characters.",
          maxlength: "City must contain at most 25 characters."
        },
        "invoice_request[details][customer][state]": {
          required: "State is required.",
          minlength: "State must contain at least 2 characters.",
          maxlength: "State must contain at most 25 characters."
        },
        "invoice_request[details][customer][country]": {
          required: "Country is required."
        },
        "invoice_request[from_date]": {
          required: "From date is required."
        },
        "invoice_request[to_date]": {
          required: "To date is required."
        }
      },
      errorPlacement: function(error, element) {
        if (element.hasClass("line-item-task-select")) {
          console.log(element)
          error.addClass('text-danger');
          error.insertAfter(element.next('.select2'));
        } else {
          error.addClass('text-danger');
          error.insertAfter(element);
        }
      }

    });
    $(".left-section input, .right-section input").on('input', function() {
      $(".invoice_validation").valid();
    });

    $.validator.addMethod("taskRequired", $.validator.methods.required, "Task name is required.");
    $.validator.addClassRules("line-item-task-select", { taskRequired: true });
    $.validator.addClassRules("line-item-name", { taskRequired: true });

    $.validator.addMethod("unitRequired", $.validator.methods.required, "Unit is required.");
    $.validator.addClassRules("line-item-unit-select", { unitRequired: true });

    $.validator.addMethod("unitPriceRequired", $.validator.methods.required, "Unit price is required.");
    $.validator.addClassRules("line-item-per-unit-amount", { unitPriceRequired: true });

    $.validator.addMethod("quantityRequired", $.validator.methods.required, "Quantity is required.");
    $.validator.addClassRules("line-item-unit-quantity", { quantityRequired: true });

    $.validator.addMethod("amountRequired", $.validator.methods.required, "Amount is required.");
    $.validator.addClassRules("line-item-amount", { amountRequired: true });


    $('#new_invoice_tax').validate({
      rules: {
        'invoice_tax[tax_type]': {
          required: true, // Make the field required
          minlength: 1 // Minimum length of 1 character
        },
        'invoice_tax[rate]': {
          required: true, // Make the field required
          number: true, // Must be a number
          min: 0, // Minimum value of 0
          max: 100, // Maximum value of 100
          twoDecimals: true
        }
      },
      messages: {
        'invoice_tax[tax_type]': {
          required: "Please enter a tax name.",
          minlength: "Tax name must be at least 1 character long."
        },
        'invoice_tax[rate]': {
          required: "Please enter a tax rate.",
          number: "Tax rate field only accepts number and dot.",
          min: "Tax rate must be at least 0.",
          max: "Tax rate cannot exceed 100.",
          twoDecimals: "Please enter only 2 digits after decimal"
        }
      },
      submitHandler: function(form) {
        // Hide the off-canvas
        $('.offcanvas').removeClass('show'); // Hide the off-canvas
        $('body').removeClass('offcanvas-open'); // Re-enable scrolling
        $('.offcanvas-backdrop').remove(); // Remove the backdrop if needed
        $('body').css('overflow', '');
        return true; // Allow normal form submission without interference
      }
    });

    $('#tax-select-box').on('change', function() {
      var selectedOption = $(this).find('option:selected');
      var rate = selectedOption.data('rate');

      let total = 0
      $('.line-item-amount').each(function(index, element) {
        if($(element).val().length > 0){
          total = total + parseFloat($(element).val())
        }
      })
      $('#invoice_request_line_items_amount').val(total)

      let tax_amount = 0
      if (rate > 0){
        tax_amount = ((total * rate)/100)
        total = (total + tax_amount)
      }
      $('#invoice_request_tax_amount').val(tax_amount)
      var advanceAmount = $('#invoice_request_advance_amount').val() || 0
      $('#invoice_request_total_invoice_amount').val(total)
      var adjustmentAmount = $('#invoice_request_adjustment_amount').val() || 0
      $('.payable_amount').val(parseFloat(total) - parseFloat(advanceAmount) - parseFloat(adjustmentAmount))
    });

    $('.add_note').on('click', function(){
      $('.notes').removeClass('d-none')
    })

    $("#send_invoice_form").validate({
      rules: {
        "email": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "email": {
          required: "Please enter invoice number.",
          emailregex: 'Please enter a valid email address'
        }
      }
    });

    $("#invoice_advance_amount, #invoice_adjustment_amount").on('input', function() {
      var val = $(this).val();
      // Remove any characters after the second decimal point
      if(val.indexOf('.') !== -1) {
        var splitVal = val.split('.');
        if(splitVal[1].length > 2) {
          splitVal[1] = splitVal[1].substring(0, 2);
          val = splitVal.join('.');
          $(this).val(val);
        }
      }
    });

    $.validator.addMethod('number_regex', function(value, element){
      return this.optional(element) || /^[0-9.]+$/.test(value);
    },'Only digit are allowed');

    jQuery.validator.addMethod("emailRegex", function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/i.test(value);
    }, "Enter valid email address");

    $.validator.addMethod("twoDecimals", function(value, element) {
      return this.optional(element) || /^\d+(\.\d{0,2})?$/.test(value);
    });
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });
    $.validator.addMethod("alphanumeric", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9 ]+$/.test(value);
    });

    if($('#project-invoice').val().length > 0 ){
      $('.invoice_line_items_section').removeClass('active')
    }else{
      $('.invoice_line_items_section').addClass('active')
    }

    if($('.nested-fields').length > 1 || $('.custom-task-items').length > 1){
      $('.no_line_item').hide()
      loadSelectForInvoiceTask()
    }else{
      $('.no_line_item').show()
    }

    $(".fromDate").datepicker({
      format: 'dd-mm-yyyy',
      autoclose: true,
    }).on('changeDate input', function (selected) {
        if (!$(this).val()) {
          $('.toDate').datepicker('setStartDate', null);
        } else if (selected.date != undefined) {
          var minDate = new Date(selected.date.valueOf());
          $('.toDate').datepicker('setStartDate', minDate);
        }
    });

    $(".toDate").datepicker({
         format: 'dd-mm-yyyy',
         autoclose: true,
    }).on('changeDate input', function (selected) {
      if (!$(this).val()) {
        $('.fromDate').datepicker('setEndDate', null);
      } else if (selected.date != undefined) {
        var minDate = new Date(selected.date.valueOf());
        $('.fromDate').datepicker('setEndDate', minDate);
      }
    });

    setTimeout(function() {
      $('#project-invoice').change(function() {
        showTaskFields();
      })
    }, 100)

    $('.line-item-per-unit-amount, #invoice_adjustment_amount').on('keydown', function(e) {
      if (e.key === '-' || e.key === '+') {
        // Prevent the default behavior
        e.preventDefault();
      }
    });

    $('#invoice_to_date, #invoice_from_date').on('change', function(argument) {
      var startDate = $('#invoice_from_date').val()
      var endDate = $('#invoice_to_date').val()

      if (startDate.length > 0 && endDate.length > 0) {
        if($('#invoice_request_custom_invoice_true').is(":checked") != true ){
          showTaskFields();
        }
      } else {
        $('.invoice_line_items_section').addClass('active')
      }
    })

    $('.delete_custom_line_item').on('click', function() {
      this.closest('.custom-task-items').remove();
    });
  }

  addLineItem(){
    $(".add-invoice-line-item").on('cocoon:before-insert', function(e, insertedItem) {
      $(insertedItem).find('input.line-item-name').parent().addClass('d-none')
      $(insertedItem).find('select.line-item-task-select').parent().removeClass('d-none')

      $(insertedItem).find('input.line-item-unit').parent().addClass('d-none')
      $(insertedItem).find('select.line-item-unit-select').parent().removeClass('d-none')
      let options = [
            ["Select", ""],
            ["Day", "day"],
            ["Hour", "hour"],
            ["Week", "week"],
            ["Month", "month"],
            ["Fixed Rate", "fixed_rate"]
        ];

      let $selectElement = $(insertedItem).find('select.line-item-unit-select');
      $selectElement.empty();
      $.each(options, function(index, option) {
        $selectElement.append(new Option(option[0], option[1]));
      });

      var descriptionField = $(insertedItem).find('input.description-field');

    });
    showLineItems()
    loadSelectForInvoiceTask()
  }

  addCustomLineItem(){
    $(".add-invoice-line-item").on('cocoon:before-insert', function(e, insertedItem) {
      $(insertedItem).find('select.line-item-task-select').parent().addClass('d-none')
      $(insertedItem).find('input.line-item-name').parent().removeClass('d-none')
      let options = [
            ["Select", ""],
            ["Day", "day"],
            ["Hour", "hour"],
            ["Week", "week"],
            ["Month", "month"],
            ["Fixed Rate", "fixed_rate"]
        ];

        let $selectElement = $(insertedItem).find('select.line-item-unit-select');
        $selectElement.empty();
        $.each(options, function(index, option) {
          $selectElement.append(new Option(option[0], option[1]));
        });
      var descriptionField = $(insertedItem).find('input.description-field');
    });
    showLineItems()
  }
  areBothSectionsEmpty() {
    const isLeftEmpty = $(".left-section").toArray().every(field => !$(field).val());
    const isRightEmpty = $(".right-section").toArray().every(field => !$(field).val());
    return isLeftEmpty && isRightEmpty;
  }
}

window.loadSelectForInvoiceTask = function() {
  $('select.line-item-task-select').each(function(index, item) {
    if($(item).data('selecttwo') == true){
      $(item).select2()
    }
  })
}

window.showTaskFields = function() {
  console.log('Clicked')
  var startDate = $('#invoice_from_date').val()
  var endDate = $('#invoice_to_date').val()
  var projectId = $('#project-invoice').find(":selected").val()
  $.each(document.getElementsByClassName('remove_fields'), function(index, item) {
    console.log('')
    $(item).closest('.nested-fields').find("input[type='hidden'][name$='[_destroy]']").val('true');
    $(item).closest('.nested-fields').hide(); // Optionally hide the field
    $(item).trigger('change'); // Notify form about changes
  });

  if(projectId.length > 0 && startDate.length > 0 && endDate.length > 0 || $('') ){
    $('.invoice_line_items_section').removeClass('active')
  }else{
    $('.invoice_line_items_section').addClass('active')
  }

  $('.invoice-icone-enable').removeAttr("disabled")
  $.ajax({
    url: '/organizers/invoice_line_items/load_tasks',
    method: 'GET',
    data: {
      project_id: projectId,
      start_date: startDate,
      end_date: endDate
    },
    success: function(response) {
      console.log(response.tasks)
      $('#task_list_option').val(JSON.stringify(response.tasks));
      $('#billing_logs').val(JSON.stringify(response.billings));
      $('.currency-symbol').text(`(${response.billings.currency})`)
    }
  });
}

window.showLineItems = function() {
  $(".add-invoice-line-item a").click()
  if($('.nested-fields').length > 1 || $('.custom-task-items').length > 1){
    $('.no_line_item').hide()
  }else{
    $('.no_line_item').show()
  }
}
