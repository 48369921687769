import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organizer-assets"
export default class extends Controller {
  connect() {

    $(".filter-btn").click(function(){
      $(".organizer-assets").toggleClass("order-enable");
      $(".organizer-allocation").toggleClass("order-enable");
      $(".organizer-asset-category").toggleClass("order-enable");
    });

    $('#allocation_assign_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $('#allocation_return_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $('#datepicker').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })


    $(".filter-btn").click(function(){
      $(".employee-assets").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".employee-assets-issue").toggleClass("order-enable");
    });


    customSelect2('#employee_select', true);
    customSelect2('#organizer_asset_select', true);
    customSelect2('#category_select', true);
    customSelect2('#status_select', true);
    $(".org-assets-sold").validate({
      rules: {
        "organizer_asset[sold_description]": {
          required: true,
          minlength: 2,
          maxlength: 500
        },
        "organizer_asset[damaged_reason]": {
          required: true,
          minlength: 2,
          maxlength: 500
        }
      },
      messages: {
        "organizer_asset[sold_description]": {
          required: "Please enter description.",
          minlength: 'Description must be between 2 and 500 characters.',
          maxlength: 'Description must be between 2 and 500 characters.'
        },
        "organizer_asset[damaged_reason]": {
          required: "Please enter reason.",
          minlength: 'Reason must be between 2 and 500 characters.',
          maxlength: 'Reason must be between 2 and 500 characters.'
        }
      }
    });
    $(".AssetsFrom").validate({
      rules: {
        "organizer_asset[name]": {
          required: true,
          minlength: 2,
          maxlength: 50
        },
        "organizer_asset[catgory]": {
          required: true
        },
        "organizer_asset[lable]": {
          required: true
        }
      },
      messages: {
        "organizer_asset[name]": {
          required: "Please enter asset name.",
          minlength: 'The asset name must be between 2 and 50 characters long.',
          maxlength: 'The asset name must be between 2 and 50 characters long.'
        },
        "organizer_asset[catgory]": {
          required: "Please select asset catgory."
        },
        "organizer_asset[lable]": {
          required: 'Please enter asset lable.'
        }
      }
    });

    $(".AllocationFrom").validate({
      errorPlacement: function (error, element) {
        if (element.attr("id") === "employee_select") {
            error.appendTo("#error_employee_select_error"); // Place error below the select
        } else if(element.attr('id') === 'organizer_asset_select'){
            error.appendTo("#error_organizer_asset_select_error");
        } else {
          error.insertAfter(element); // Default placement for other fields
        }
      },
      rules: {
        "allocation[employee_id]": {
          required: true
        },
        "allocation[organizer_asset_id]": {
          required: true
        },
        "allocation[assign_date]": {
          required: true
        }
      },
      messages: {
        "allocation[employee_id]": {
          required: "Please select employee name."
        },
        "allocation[organizer_asset_id]": {
          required: "Please select asset type."
        },
        "allocation[assign_date]": {
          required: 'Please select assign date.'
        }
      }
    });

    $("#ReturnAllocation").validate({
      rules: {
        "allocation[return_date]": {
          required: true
        },
        "allocation[return_reason]": {
          required: true,
          minlength: 10,
          maxlength: 500
        }
      },
      messages: {
        "allocation[return_date]": {
          required: "Please select return date."
        },
        "allocation[return_reason]": {
          required: "Please enter reason.",
          minlength: 'Please enter atleast 10 character',
          maxlength: 'Please enter less then 500 character'
        }
      }
    });

    $(".add-asset-image").click();

  }
}


window.previewImage = function(element){
  if(element.files && element.files[0]) {
    var imgTag = $(element).prev().find('img')
    var reader = new FileReader();
    reader.onload = function(e){
      imgTag.attr('src', e.target.result);
      imgTag.attr('class', 'upload-image-src');
      if ($(".empty-src").length == 0) {
        $(".add-asset-image").click();
      }
    }
    reader.readAsDataURL(element.files[0]);
  }
}

window.fullscreenImg = function(element){
  var url = element.dataset.src
  var dataId = element.dataset.id
  var imgHash = $('#all_images').val().replace("value ", "").replaceAll("=>", ":")
  var imgJson = JSON.parse(imgHash)
  $('#asset-images-full').attr('src', url);
  $('#imgDataId').text(dataId);
  if (imgJson[parseFloat(dataId)-1] == undefined) {
    $('.left-img-icon').hide()
    $('.right-img-icon').show()
  }else if (imgJson[parseFloat(dataId)+1] == undefined) {
    $('.left-img-icon').show()
    $('.right-img-icon').hide()
  }
}

window.previousImg = function(){
  var dataId = parseFloat($('#imgDataId').text())
  var imgHash = $('#all_images').val().replace("value ", "").replaceAll("=>", ":")
  var imgJson = JSON.parse(imgHash)
  var currentDataId = dataId-1
  $('.right-img-icon').show()
  $('.left-img-icon').show()
  $('#imgDataId').text(dataId-1)
  $('#asset-images-full').attr('src', imgJson[dataId-1]);
  if (imgJson[dataId-2] == undefined) {
    $('.left-img-icon').hide()
  }
}

window.nextImg = function(){
  var dataId = parseFloat($('#imgDataId').text())
  var imgHash = $('#all_images').val().replace("value ", "").replaceAll("=>", ":")
  var imgJson = JSON.parse(imgHash)
  var currentDataId = dataId+1
  $('.left-img-icon').show()
  $('.right-img-icon').show()
  $('#imgDataId').text(dataId+1)
  $('#asset-images-full').attr('src', imgJson[dataId+1]);
  if (imgJson[dataId+2] == undefined) {
    $('.right-img-icon').hide()
  }
}