import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reimbursment"
export default class extends Controller {
  connect() {
    // customSelect2('#status', true, 'All')
    $(".filter-btn").click(function(){
      $(".employee-reimbursement").toggleClass("order-enable");
    });
    $(".filter-btn").click(function(){
      $(".organizer-expense-report").toggleClass("order-enable");
    });
    customSelect2('#status', true);

    $(".reimbursments").validate({
      rules: {
        "reimbursment[title]": {
          required: true,
          minlength: 2,
          maxlength: 60,
          nameregex: true
        },
        "reimbursment[date]": {
          required: true
        },
        "reimbursment[amount]": {
          required: true,
          number_regex_decimal: true,
          maxlength: 14,
          minlength: 3
        },
        "reimbursment[description]": {
          required: true,
          minlength: 10
        }
      },
      messages: {
        "reimbursment[title]": {
          required: 'Please enter title.',
          minlength: 'Title should be between 2-60 character.',
          minlength: 'Title should be between 2-60 character.',
          nameregex: 'only alphabets are allowed'
        },
        "reimbursment[date]": {
          required:  'Date is required.'
        },
        "reimbursment[amount]": {
          required: 'Please enter amount.',
          maxlength: 'Only 10 digits are allowed',
          minlength: 'Please enter minimum 3 digits'
        },
        "reimbursment[description]": {
          required: 'Please enter description.',
          minlength: 'Please enter atleast 10 character.'
        }
      }
    });

    $(".reimbursment-reject-reason").validate({
      rules: {
        "reimbursment[reason]": {
          required: true,
          minlength: 10
        },
        'reimbursment[paid_mode]': {
          required: true
        },
        "reimbursment[approx_paid_date]": {
          required: true
        },
        "reimbursment[cheque_number]": {
          digits: true,
          minlength: 16,
          maxlength: 16
        }
      },
      messages: {
        "reimbursment[reason]": {
          required: 'Please enter comment.',
          minlength: 'Please enter atleast 10 character.'
        },
        'reimbursment[paid_mode]': {
          required: 'Please select mode of payment.'
        },
        "reimbursment[approx_paid_date]": {
          required: 'Please enter paid date.',
        },
        "reimbursment[cheque_number]": {
          digits: 'Please enter only numbers.',
          minlength: 'Cheque number must be 16 digits.',
          maxlength: 'Cheque number must be 16 digits.'
        }
      }
    });
    function restrictInputLength(element, maxLength) {
      $(element).on('keydown', function(event) {
        if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab') {
          return;
        }
        if (!/^\d$/.test(event.key)) {
          event.preventDefault();
        }
        if ($(this).val().length === maxLength) {
          event.preventDefault();
        }
      });
    }
    restrictInputLength('#reimbursment_cheque_number', 16);
    $(document).on('change', '#reimbursment_paid_mode', function () {
      console.log('change function called')
      const selectedOption = $(this).val();
      if (selectedOption == 'cheque') {
        $('#cheque_number_field').show(); // Show the cheque number field
      } else {
        $('#cheque_number_field').hide(); // Hide the cheque number field
      }
    });
    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d,]+(\.\d+)?$/.test(value);
    }, 'Only digits, commas, and decimals are allowed');
    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z. ]+$/.test(value);
    });

    $("#reimbursment_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      endDate: '+0d',
    });

    $("#reimbursment_approx_paid_date").datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      startDate: '+1d',
    });

    $('#reimbursment_amount').on('input', function(event) {
      let value = event.target.value.replace(/[^\d.]/g, ''); // Remove non-numeric and non-decimal characters
      let [wholeNumber, decimal] = value.split('.');
      if (!wholeNumber && !decimal) {
        event.target.value = '';
        return;
      }
      wholeNumber = wholeNumber || '0';
      event.target.value = parseFloat(wholeNumber).toLocaleString('en-IN');
      if (decimal) {
        decimal = decimal.slice(0, 2);
        event.target.value = event.target.value + '.' + decimal;
      } else if (value.includes('.')) {
        event.target.value = event.target.value + '.';
      }
    });

    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        $(".org-reimbursement").toggleClass("order-enable");
      });
    })

    let reimbursementContainer = $('#reimbursment_documents');

    function updateSummary() {
      console.log('update function called');
      let dates = [];
      let totalAmount = 0;

      $(reimbursementContainer).find('.nested-fields:visible').each(function () {
        let dateValue = $(this).find('.invoice_datepicker').val();
        let amountValue = parseFloat($(this).find('.amount').val()) || 0;
        if (dateValue) dates.push(dateValue);
        totalAmount += amountValue;
      });

      if (dates.length) {
        dates.sort();
        let minDate = dates[0];
        let maxDate = dates[dates.length - 1];

        $('#reimbursment_edit_date').val(
          dates.length === 1 ? `${minDate} - ${minDate}` : `${minDate} - ${maxDate}`
        );
      } else {
        $('#reimbursment_edit_date').val('No dates selected');
      }

      $('#reimbursment_amount').val(totalAmount.toFixed(2));
    }

    $(reimbursementContainer).on('change', '.invoice_datepicker, .amount', updateSummary);
    $(reimbursementContainer).on('cocoon:after-insert cocoon:after-remove', updateSummary);
    updateSummary();
  }
}
