import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="benefits"
export default class extends Controller {
  connect() {
    $('#compensatory_off_request_apply_date').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $(".filter-btn").click(function(){
      $(".employee-comp-off").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".employee-comp-off-request").toggleClass("order-enable");
    });


    $(".filter-btn").click(function(){
      $(".employee-employee-badge").toggleClass("order-enable");
    });

    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+0d',
      autoclose: true
    })

    $.validator.addMethod("positive", function(value, element) {
      return Number(value) > 0;
    }, "Please enter a positive number.");

    $('#badge_per_point_amount').on('input', function(){
        var val = $(this).val();
        var decimalIndex = val.indexOf('.');
        if(decimalIndex !== -1) {          
          var integerPart = val.substring(0, decimalIndex);
          var fractionalPart = val.substring(decimalIndex + 1);
                    
          if(fractionalPart.length > 2) {
              fractionalPart = fractionalPart.substring(0, 2);
          }          
          val = integerPart + '.' + fractionalPart;
                    
          $(this).val(val);
        }
    })

    $('#badge_points').on('input', function(){
        var val = $(this).val();
        var decimalIndex = val.indexOf('.');
        if(decimalIndex !== -1) {          
          var integerPart = val.substring(0, decimalIndex);
          var fractionalPart = val.substring(decimalIndex + 1);
                    
          if(fractionalPart.length > 2) {
              fractionalPart = fractionalPart.substring(0, 2);
          }          
          val = integerPart + '.' + fractionalPart;
                    
          $(this).val(val);
        }
    })

    customSelect2('#employee_select', true);
    customSelect2('#employee_badge_badge_id', true);
    $(".CompOffsFrom").validate({
      rules: {
        "compensatory_off_request[apply_date]": {
          required: true
        },
        "compensatory_off_request[totel_hour]": {
          required: true,
          min: 1,
          max:24
        },
        "compensatory_off_request[content]": {
          required: true,
          minlength: 10
        },        
      },
      messages: {
        "compensatory_off_request[apply_date]": {
          required: "Please select apply date.",
        },
        "compensatory_off_request[totel_hour]": {
          required: "Please select total hour.",
          min: 'Total hours should be between 1-24',
          max: 'Total hours should be between 1-24'
        },
        "compensatory_off_request[content]": {
          required: "Please enter description.",
          minlength: 'Please enter atleast 10 character'
        },
      }
    });

    $(".BadgeFrom").validate({
      rules: {
        "badge[photo]": {
          required: true
        },
        "badge[name]": {
          required: true
        },
        "badge[points]": {
          required: true,
          positive: true
        },
        "badge[per_point_amount]": {
          required: true,
          maxlength: 10,
          number_regex_decimal: true
        }
      },
      messages: {
        "badge[photo]": {
          required: "Please upload the image."
        },
        "badge[name]": {
          required: "Please Select Name."
        },
        "badge[points]": {
          required: "Please Select Points.",
          positive: "Please enter a positive number."
        },
        "badge[per_point_amount]": {
          required: "Please enter per point amount.",
          maxlength: 'Only 10 digits are allowed'
        }
      }
    });

    $(".EmployeeBadgeFrom").validate({
      rules: {
        "employee_badge[assigning_at]": {
          required: true
        },
        "employee_badge[employee_id]": {
          required: true
        },
        "employee_badge[badge_id]": {
          required: true
        },
        "employee_badge[quantity]": {
          required: true,
          positive: true
        }
      },
      messages: {
        "employee_badge[assigning_at]": {
          required: "Please select assign date."
        },
        "employee_badge[employee_id]": {
          required: "Please select Employee."
        },
        "employee_badge[badge_id]": {
          required: "Please select Badge."
        },
        "employee_badge[quantity]": {
          required: "Please select Quantity.",
          positive: "Please enter a positive number."
        }
      }
    });
    $.validator.addMethod('number_regex_decimal', function(value, element) {
      return this.optional(element) || /^[\d.]+(\.\d+)?$/.test(value);
    }, 'Only digits, and decimals are allowed');
    
    $(document).on('turbo:load', function() {
      $(".filter-btn").click(function(){
        $(".organizer-compoff").toggleClass("order-enable");
      });

      $(".filter-btn").click(function(){
        $(".organizer-employee-badge").toggleClass("order-enable");
      });

      $(".filter-btn").click(function(){
        $(".organizer-asset-issue").toggleClass("order-enable");
      });
    })
  }
}